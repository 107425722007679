.body {
  background-color: pink;
}

.Mainbody {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  background-color: transparent;
}
path.path {
  fill: none;
  stroke: hsl(60, 20%, 50%);
  stroke-width: 10px;
  stroke-opacity: 0.5;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 100000;
  stroke-dashoffset: -100000;
  /* animation-delay: 1s;
  animation-duration: ;
  animation-iteration-count: 1;
  animation-fill-mode: forwards; */
  /* animation: firstAnimation 2s infinite;
  animation: name duration timing-function delay iteration-count direction fill-mode; */
}

g.hexagon {
  fill: hsl(60, 10%, 95%);
}

g.visited {
  /* fill: hsl(200, 10%, 90%); */
  fill: hsl(200, 50%, 80%);
  transition: fill 0.3s;
}

g.origin {
  fill: hsl(60, 100%, 50%);
  animation: fill 10;
}

g.target {
  fill: hsl(90, 100%, 50%);
}

g.wall {
  fill: hsl(0, 20%, 70%);
}

@keyframes isVisited {
  from {
    fill: hsl(60, 10%, 95%);
  }

  to {
    fill: hsl(200, 10%, 90%);
  }
}

@keyframes pathAnimation {
  0% {
    stroke-dashoffset: -100000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

.Legends {
  display: flex;
  margin-top: 10px;
  margin-bottom: -20px;
}

.Legends .withMargin {
  margin-right: 20px;
}

.LegendsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

button {
  font-size: 17px;
  margin: 2px;
  margin-top: 5px;
}
select {
  font-size: 17px;
  margin: 2px;
  margin-top: 5px;
}

.grid {
  border-radius: 10px;
  box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, 0.3);
}

.shadow {
  -webkit-filter: drop-shadow(-2px 2px 4px rgba(0, 0, 0, 0.5));
  filter: drop-shadow(-2px 2px 4px rgba(0, 0, 0, 0.5));
  /* Similar syntax to box-shadow */
}

/* .controlPanel {
  display: flex;
  flex: 1;
  margin: 10px 0;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  background-color: white;
  padding: 15px 20px 0;
  border-radius: 10px;
  box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, 0.3);
} */

.title {
  margin: -50px 0 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 341px;
  height: 84px;
  background-image: url(../Assets/popupDesk.png);
  background-position: center;
}

.title h3 {
  user-select: none;
  max-width: 250px;
  padding: 0 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
  text-transform: uppercase;
  font-size: 21px;
  font-weight: 600;
  line-height: 2;
  color: #ffbf00;
  letter-spacing: 0;
  margin: 13px 0 0;
  text-shadow: 0 -1px 0 #fffa6d, #001b51 3px 0 0, #001b51 2.83487px 0.981584px 0,
    #001b51 2.35766px 1.85511px 0, #001b51 1.62091px 2.52441px 0,
    #001b51 0.705713px 2.91581px 0, #001b51 -0.287171px 2.98622px 0,
    #001b51 -1.24844px 2.72789px 0, #001b51 -2.07227px 2.16926px 0,
    #001b51 -2.66798px 1.37182px 0, #001b51 -2.96998px 0.42336px 0,
    #001b51 -2.94502px -0.571704px 0, #001b51 -2.59586px -1.50383px 0,
    #001b51 -1.96093px -2.27041px 0, #001b51 -1.11013px -2.78704px 0,
    #001b51 -0.137119px -2.99686px 0, #001b51 0.850987px -2.87677px 0,
    #001b51 1.74541px -2.43999px 0, #001b51 2.44769px -1.73459px 0,
    #001b51 2.88051px -0.838247px 0;
}

.controlPanel {
  margin-top: 50px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border: 1px #979797 solid;
  border-radius: 22px;
  align-items: center;
  padding: 0 20px 20px;
  box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, 0.3);
}

.content {
  display: flex;
}

.alignSelect {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 20px;
}

.alignSelect .legend {
  font-weight: 900;
  font-size: 20px;
}

.alignSelect .select {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 72px;
  height: 34px;
  border: 2px #868d96 solid;
  border-radius: 7px;
}

.alignSelect .select select {
  padding: 10px 10px 10px 10px;
  width: 100%;
  background-color: transparent;
  border: none;
  position: relative;
  line-height: 20px;
  font-size: 18px;
  font-weight: 600;
  color: #797979;
  z-index: 2;
}

.alignSelect .switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.alignSelect .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.alignSelect .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 7px;
}

.alignSelect .slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 7px;
}

.alignSelect input:checked + .slider {
  background-color: #2196f3;
}

.alignSelect input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

.alignSelect input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.alignSelect .slider.round {
  border-radius: 34px;
}

.alignSelect .slider.round:before {
  border-radius: 50%;
}

.btYellowBig {
  border-radius: 34px;
  border: 2px transparent solid;
  /* border: 2px transparent solid; */
  box-shadow: 0 0 0 5px #002043, 0 0 0 6px #7c92b0;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  position: relative;
  background-color: #ffbf00;
  border-top-color: #ffe100;
  border-bottom-color: #f88700;
  width: 170px;
  height: 50px;
  margin-right: 40px;
  margin-left: 50px;
}

.btYellowBig:disabled {
  cursor: auto;
  background-color: #d8d6d3;
  border-top-color: #f8f8f8;
  border-bottom-color: #96928b;
  overflow: visible;
}

.btYellowBig strong {
  flex: 1;
  font-family: "Nunito";
  font-weight: 900;
  font-size: 35px;
  color: #001b4d;
  letter-spacing: 0;
  margin: 0 0 0 0;
  text-transform: uppercase;
  word-break: break-word;
}

.info {
  border: none;
  background-color: transparent;
  font-size: 30px;
}

.modalBackground {
  background-color: rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalContainer {
  width: auto;
  height: auto;
  min-height: 250px;
  min-width: 250px;
  border-radius: 12px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  padding: 25px;
  padding-top: 15px;
}

/* .modalBody {
  display: flex;
} */

.originContainer,
.targetContainer,
.pathWallContainer {
  display: flex;
  flex-direction: row;
  justify-items: center;
  margin: 10px;
  margin-top: 15px;
}

.titleCloseButton button {
  border: none;
  background-color: transparent;
  font-weight: 900;
  font-size: large;
}

.interactingGuide {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  font-size: 20px;
}

.interactingGuide strong {
  margin-left: 5px;
}

.Footer {
  margin-top: -10px;
}

.Footer a {
  color: black;
  font-size: 25px;
  margin-left: 10px;
}
